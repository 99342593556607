.about .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    
  }
  
  .about .content .askema {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #eee;
    z-index: -999;
    width: 100%;
    
  }
  
  .askema .text {
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 6% 10%;
  }

.about    .askema .text h2,
.about   h2 {
        font-size: 36px;
        font-weight: 500;
        color:  #3F2305;
    }
 
.about .askema .image{
    z-index: 999;
}

.about  .askema .image img {
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    
  }

 .about .askema .text p{
    color: #000;
    /* font-size: 20px; */
    text-align: center;
  }
  
  .about .team{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: -999;
  }


  .about .content .problem{
    margin-top: 100px;
    max-width: 50%;
    text-align: center;
  }

  .about .content .problem h2,
  .about .content .solution h2{
    font-size: 36px;
    font-weight: 500;
    color:  #3F2305;
    margin-bottom: 0;

  }

  .about .content .solution,
  .about .content .vision{
    margin-top: 100px;
    max-width: 50%;
    text-align: center;
  }


.about .content .problem p,
.about .content .solution p,
.about .content .vision p

{
    font-weight: lighter;
    /* color: black; */
}

.about .content hr{
    width: 40px;
    height: 1px;
    background-color:  #3F2305;
    margin-bottom: 40px;
    margin-top: 0;
}




.about .content .box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    max-width: 90%;
    margin: 0 auto;
  }
  

  .about .content  .card {
    width: 300px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .about .content .card:hover {
    transform: scale(1.05);
  }
  

  .about .content .card img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 50px 0 0 0;
  }

  .about .content .applicable img{
    border-radius: 0 0 0 0;
  }

  .about .content .affordable img{
    border-radius: 0 50px 0 0;
  }
  

  .about .content .card h3 {
    font-size: 18px;
    color:  #3F2305;
  }
  

  .about .content .card p {
    font-size: 14px;
    margin-top: 10px;
  }
  

  .about .content .box .applicable {
    background-color: #F2EAD3;
  }

  .about .content h2 {
    font-size: 36px;
    margin-bottom: 0;
    color:  #3F2305;
    margin-top: 100px;
  }



  
  



  @media screen and (max-width: 988px) {
    .about .askema {
      flex-direction: column; 
      width: 100%;
      height: auto;
      /* height: 40vh;d */
    }
  
    .about    .askema .text h2 {
        font-size: 26px;
        font-weight: 500;
        color:  #3F2305;
    }

    .about .askema .text {
        width: 100%;
        margin-top: 10%;
    }
  
    .about .askema .image img {
        display: none;
    }

    .about .content .problem{
        margin-top: 100px;
        max-width: 90%;
        text-align: center;
      }

    
      .about .content .solution{
        margin-top: 100px;
        /* max-width: 90%; */
        text-align: center;
      }

      .about .content .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
        max-width: 90%;
        margin: 0 auto;
      }
      
      
    
  }

  @media screen and (max-width: 600px) {
  iframe{
    width: 100%;
    /* height: 100%; */
    border: none;
    border-radius: 10px;
  }
}
.body{
    margin: 0;
    justify-content: center;
    align-items: center;
    display: flex;    
}

.body .image{
    top: -1em;
    position: absolute;
    width: 100%;
    height: 600px;
    z-index: -1;
}
.body .image::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -999;
  }

.body .image img{
    position: absolute;
    width: 100%;
    max-height: 600px;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    /* filter: blur(5px ); */
    
}


@media screen and (max-width: 800px){
    .body{
        flex-direction: column;
    }
    
    .body .image img{
        position: absolute;
        width: 100%;
        height: 600px;
        top: 0;
        left: 0;
        right: 0;
    }

}
    

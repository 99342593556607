.form-container {
    width: 600px;
    margin: 10px auto;
  }
  
  .form-container  label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-container input[type="text"],
  .form-container input[type="date"],
  .form-container textarea {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-container input[type="file"] {
    margin-top: 4px;
    margin-bottom: 10px;
  }
  
  .form-container  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #45a049;
  }
  
/* header.css */
.navbar{
  background-color: #1b0101;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.dropdown-menu {
  padding: 0;
  margin: 0;
  min-width: 20rem;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  color: #333;
  text-decoration: none;
}

.navbar.scrolled {
  background-color: #1b0101;
}